<template>
  <div class="questionLocal">
    <div class="search-box">
      <div class="input-box">
        <el-input
          v-model="question"
          placeholder="请输入内容"
          class="input-with-select"
          :debounce="500"
          @keyup.enter.native="searchList"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchList"
          ></el-button>
        </el-input>
      </div>
      <div class="search-item">
        <div class="title">题型：</div>
        <template v-for="(v, i) in questionList">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, questionList)"
          >
            {{ v.label }}
          </p>
        </template>
      </div>
      <div class="search-item">
        <div class="title">难度：</div>
        <template v-for="(v, i) in difficultyList">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, difficultyList)"
          >
            {{ v.label }}
          </p>
        </template>
      </div>
      <div class="search-item">
        <div class="title">年份：</div>
        <template v-for="(v, i) in searchYearList">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, searchYearList)"
          >
            {{ v.label }}
          </p>
        </template>
      </div>
      <div class="search-item">
        <div class="title">考试类型：</div>
        <template v-for="(v, i) in examTypeOptions">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, examTypeOptions)"
          >
            {{ v.label }}
          </p>
        </template>
      </div>
      <!-- <div v-if="$parent.getLevelId == 2" class="search-item">
        <div class="title">来源：</div>
        <template v-for="(v, i) in questionSourceItemJunior">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, questionSourceItemJunior)"
          >
            {{ v.label }}
          </p>
        </template>
      </div> -->
      <!-- <div v-if="$parent.getLevelId == 3" class="search-item">
        <div class="title">来源：</div>
        <template v-for="(v, i) in questionSourceItemHigh">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, questionSourceItemHigh)"
          >
            {{ v.label }}
          </p>
        </template>
      </div> -->
      <div class="search-item">
        <div class="title">过滤：</div>
        <el-checkbox-group v-model="checkList" @change="filterList">
          <el-checkbox :label="1">图片题</el-checkbox>
          <el-checkbox :label="2">无答案解析</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="menu-box">
      <div
        v-for="(v, i) in sortTypeList"
        :key="i"
        :class="{ on: v.isClick }"
        @click="setItem(v, sortTypeList)"
      >
        {{ v.name }}
      </div>
      <!-- <el-switch
          v-model="noImg"
          active-text="包含图片题"
          inactive-text="不包含图片题"
        >
        </el-switch>
        <el-switch
          v-model="hasAnswer"
          active-text="所有题"
          inactive-text="有答案解析"
        >
        </el-switch> -->
    </div>
    <div v-if="loading" v-loading="loading" class="loading-box"></div>
    <div v-if="!loading" class="question-box">
      <div v-for="(v, i) in examquestionData" :key="i" class="question-list">
        <div class="question-id">试题ID：{{ v.id }}</div>
        <div style="padding: 0 12px 12px" v-html="v.question"></div>
        <div class="btm-box">
          <div class="tips">
            <!-- <span> 收录于{{ v.createTime | setTimeStyle }}</span> -->
            <span>{{ v.typeName }}</span>
            <span> {{ v.difficulty | setName(difficultyListCopy) }}</span>
          </div>

          <div class="btn-box">
            <span :class="{ succ: v.isRelation }" @click="relation(v)">
              <template v-if="v.isRelation">已</template>收藏</span
            >
            <span @click="show(v)">解析</span>
            <span @click="situation(v)">考情</span>
            <!-- <span @click="$parent.similarity(v)">同类题</span> -->
            <el-button
              v-if="$parent.planQuestionList.indexOf(v.id) == -1"
              type="primary"
              class="btn-all"
              @click="$parent.exampaperplanquestion(v)"
            >
              加入试题篮
              <i class="el-icon-plus el-icon--right"></i>
            </el-button>
            <el-button
              v-else
              type="info"
              @click="$parent.exampaperplanquestionDel(v)"
            >
              移除试题篮
              <i class="el-icon-minus el-icon--right"></i>
            </el-button>
          </div>
        </div>
        <!-- 考情 -->
        <div v-if="situationArr.indexOf(v.id) != -1" class="situation-box">
          <template
            v-if="v.situation && v.situation.anyExamUseQuestion.length == 0"
          >
            数据尚未同步
          </template>
          <template v-else>
            <p>
              <span class="situation-box-name">考情：</span>本题共
              <span>{{ v.situation.countReply }}</span> 人使用,共{{
                v.situation.examNum
              }}场考试
            </p>
            <div
              v-for="(m, n) in v.situation.anyExamUseQuestion"
              :key="n"
              class="situation-list"
            >
              <div class="situation-list-left">
                <p>{{ m.examName }}</p>
                <div>
                  <span class="situation-list-name">{{ m.examDate }}</span>
                  <span> | </span>
                  <span class="situation-list-name"
                    >共{{ m.countReply }}人参考</span
                  >
                  <span> | </span>
                  <span class="situation-list-name">{{ m.schoolLevel }}</span>
                </div>
              </div>
              <div class="situation-list-right">
                {{ m.scoreRate | setNumber }}
              </div>
            </div>
          </template>
        </div>
        <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
          <div v-if="v.newQuestion" class="new-question-box">
            <p v-if="v.newQuestion" class="question-tips">
              当前试题没有找到答案解析，已推荐同类题
            </p>
            <div class="analysis-item">
              【同类题】
              <div v-html="v.newQuestion"></div>
            </div>
            <el-button
              v-if="planQuestionList.indexOf(v.newId) == -1"
              type="primary"
              class="btn-all"
              @click="exampaperplanquestion(v, 1)"
            >
              加入试题篮
              <i class="el-icon-plus el-icon--right"></i>
            </el-button>
            <el-button
              v-else
              type="info"
              @click="exampaperplanquestionDel(v, 1)"
            >
              移除试题篮
              <i class="el-icon-minus el-icon--right"></i>
            </el-button>
          </div>

          <div
            v-if="v.knowledgeNames && v.knowledgeNames.split('#').length > 0"
            class="know-box analysis-item"
          >
            【知识点】
            <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
              {{ m }}
            </div>
          </div>
          <div v-if="v.answer && v.answer.length > 0" class="analysis-item">
            【答案】
            <div v-html="v.answer"></div>
          </div>
          <div v-if="v.analysis && v.analysis.length > 0" class="analysis-item">
            【解答】
            <div v-html="v.analysis"></div>
          </div>
          <div v-if="v.analyse && v.analyse.length > 0" class="analysis-item">
            【分析】
            <div v-html="v.analyse"></div>
          </div>
          <div v-if="v.ability && v.ability.length > 0" class="analysis-item">
            【点评】
            <div v-html="v.ability"></div>
          </div>
          <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
        </div>
      </div>
    </div>
    <div v-if="examquestionData.length == 0 && !loading" class="none-list">
      <img src="@/static/resources/none.svg" alt="" />
      <p>暂未找到数据</p>
    </div>
    <el-pagination
      :hide-on-single-page="true"
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="current"
      @current-change="pageList"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getquestiontype } from "@/core/api/resource/breakdownPaper";
import {
  questionType,
  examTypeOptions,
  difficultyList,
  difficultyListCopy,
} from "@/core/util/constdata";
import {
  examquestionSearch,
  answer,
  situation,
  examquestionrelationMy,
  examquestionrelationDel,
} from "@/core/api/resource/knowledgePointsPaper";
const sortTypeList = [
  { name: "综合", value: 1, isClick: false },
  { name: "最新", value: 1, isClick: true },
  { name: "最热", value: 2, isClick: false },
];
export default {
  name: "QuestionLocal",
  data() {
    return {
      loading: false,
      current: 1,
      question: "",
      examquestionData: [],
      // 搜索
      searchYearList: [],
      difficultyList: difficultyList,

      questionType: questionType,
      questionList: [],
      sortTypeList: sortTypeList,
      difficultyListCopy: difficultyListCopy,
      examTypeOptions: examTypeOptions,
      checkList: [1],
      total: 0,
      planQuestionList: [],
      // 判断是否显示考情
      situationArr: [],
      // 判断列表是否显示解析
      showListArr: [],
    };
  },
  created() {
    this.setSearchList();
    this.initItem();
    this.getList();
  },
  mounted() {
    window.MathJax.typesetPromise();
  },
  updated() {
    window.MathJax.startup.defaultReady();
    // window.MathJye.LayOut(document.body);
  },
  methods: {
    // 收藏
    relation(v) {
      let data = {
        examQuestionId: v.id,
        type: 1,
      };
      if (v.isRelation == 1) {
        this.$confirm("确认取消收藏当前试题吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            examquestionrelationDel(data).then(() => {
              v.isRelation = 0;
              this.$message({
                message: "已取消收藏",
                type: "success",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消“取消收藏”操作",
            });
          });
      } else {
        examquestionrelationMy(data).then(() => {
          // console.log();
          this.$message({
            message: "已收藏",
            type: "success",
          });
          v.isRelation = 1;
        });
      }
    },
    //获取考情
    situation(v) {
      if (v.situation) {
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        return;
      }
      // console.log();
      situation(v.id).then((res) => {
        v.situation = res.data.data;
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
      });
    },
    // 切换是否显示页面列表
    show(v) {
      if (this.showListArr.indexOf(v.id) != -1) {
        this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
        return;
      }
      answer(v.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            v.newId = res.data.data.id;
            v.newXuekeId = res.data.data.xuekeId;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    initItem() {
      let str =
        "全部 中考真题 自主招生 中考模拟 中考复习 期末试题 期中试题 月考试题 开学试题 单元测验 同步练习 竞赛试题 假期作业 初高衔接";
      let arr = str.split(" ");
      this.questionSourceItemJunior = arr.map((item, index) => {
        return {
          label: item,
          value: index,
          isClick: index == 0 ? true : false,
        };
      });

      let strNew =
        "全部 高考真题 学业考试 自主招生 高考模拟 高考复习 期末试题 期中试题 月考试题 开学试题 单元测验 同步练习 竞赛试题 假期作业 模块试题";
      let arrNew = strNew.split(" ");
      this.questionSourceItemHigh = arrNew.map((item, index) => {
        return {
          label: item,
          value: index,
          isClick: index == 0 ? true : false,
        };
      });
    },
    onValue(list) {
      let value = "";
      list.forEach((index) => {
        if (index.isClick) {
          value = index.value;
        }
      });
      return value;
    },
    filterList() {
      this.current = 1;
      this.getList();
    },
    pageList(i) {
      this.current = i;
      this.getList();
    },
    getViewList(i) {
      this.current = i || this.current;
      this.getList();
    },
    getList() {
      let data = {
        size: 10,
        current: this.current,
        level: this.$parent.getLevelId,
        subjectId: this.$parent.getSubjectId,
        // planId: this.planId,
        question: this.question,
        isAllowJyeoo: 0,
      };
      this.situationArr = [];
      this.showListArr = [];
      data.isSingle = this.treeType ? 0 : 1;
      // if (this.treeType) {
      data.isUnion = this.knowType ? 0 : 1;
      // }
      if (!this.treeType) {
        data.isUnion = 1;
      }
      if (this.checkList.indexOf(1) != -1) {
        data.noImg = 1;
      } else {
        data.noImg = 0;
      }
      if (this.checkList.indexOf(2) != -1) {
        data.hasAnswer = 1;
      } else {
        data.hasAnswer = 0;
      }
      // data.noImg = this.noImg ? 0 : 1;
      // data.hasAnswer = this.hasAnswer ? 0 : 1;
      // treeType
      // knowType
      //题型
      data.typeName = this.onValue(this.questionList);
      // 难度
      data.difficulty = this.onValue(this.difficultyList);
      // 年份
      data.year = this.onValue(this.searchYearList);
      // 考试类型
      data.examType = this.onValue(this.examTypeOptions);
      // 来源
      if (this.$parent.getLevelId == 2) {
        data.questionSource = this.onValue(this.questionSourceItemJunior);
      }
      if (this.$parent.getLevelId == 3) {
        data.questionSource = this.onValue(this.questionSourceItemHigh);
      }

      // 排序  最新  最热
      data.sortType = this.onValue(this.sortTypeList);
      // typeVal 为1就是知识点 ，为2就是章节
      if (this.$parent.typeVal == 1) {
        data.knowledgeIds = this.$parent.knowledge;
        data.chapterIds = "";
        if (data.knowledgeIds.length > 15) {
          this.$message({
            message: "所选知识点不能超过15个",
            type: "warning",
          });
          return;
        }
      } else {
        data.knowledgeIds = [];
        data.chapterIds = this.$parent.chapterId;
        let arr = this.$parent.getbookchapterData.filter(
          (item) => item.number == data.chapterIds
        );
        if (arr.length > 0) {
          data.jyeooId = arr[0].jyeooId;
        } else {
          data.jyeooId = "";
        }

        // this.getbookchapterData.forEach()
      }
      this.loading = true;
      examquestionSearch(data)
        .then((res) => {
          this.loading = false;
          this.examquestionData = res.data.data.records.map((item) => {
            if (item.source) {
              item.question = item.question.replace(
                ">",
                `><span class='jyw-question-tips'>${item.source}</span>`
              );
            }

            return item;
          });
          if (res.data.data.total < 50) {
            this.total = 50;
          } else {
            this.total = res.data.data.total;
          }

          this.showListArr = [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 切换
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.value == val.value) {
          index.isClick = true;
        }
      });
      this.current = 1;
      //判断下如果存在搜索条件 就将排序方式改为综合
      if (this.question != "" && this.sortTypeList[0].name == list[0].name) {
        this.sortTypeList[0].isClick = true;
        this.sortTypeList[1].isClick = false;
        this.sortTypeList[2].isClick = false;
        return;
      }
      this.getList();
    },

    setSearchList() {
      // 搜索用的年份
      let year = new Date().getFullYear();
      this.searchYearList.push({
        label: "全部",
        value: -1,
        isClick: true,
      });
      for (let i = 0; i < 4; i++) {
        this.searchYearList.push({
          label: year - i,
          value: year - i,
          isClick: false,
        });
      }
      this.searchYearList.push({
        label: "更早",
        value: -2,
        isClick: false,
      });
      let arr = [
        {
          value: "0-1",
          label: "全部",
          isClick: true,
        },
      ];
      //难度列表循环
      this.difficultyList.map((item) => {
        item.isClick = false;
      });
      this.difficultyList = arr.concat(this.difficultyList);
      this.difficultyList = JSON.parse(JSON.stringify(this.difficultyList));
      // 通过科目、学段查询题型
      this.questionList = [];
      this.questionList.push({ label: "全部", value: -1, isClick: true });
      getquestiontype({
        levelId: this.$parent.getLevelId,
        subjectId: this.$parent.getSubjectId,
      }).then((res) => {
        res.data.data.forEach((index) => {
          this.questionList.push({
            value: index.name,
            label: index.name,
            isClick: false,
          });
        });
      });

      this.examTypeOptions.map((item) => {
        item.isClick = false;
      });
      this.examTypeOptions = [
        { label: "全部", value: -1, isClick: true },
      ].concat(this.examTypeOptions);
      this.examTypeOptions = JSON.parse(JSON.stringify(this.examTypeOptions));
    },
    searchList() {
      this.current = 1;
      this.searchKey = new Date().getTime();
      this.knowledge = [];
      this.$parent.chapterId = "";
      // difficultyList searchYearList examTypeOptions
      this.difficultyList.map((index) => {
        index.isClick = false;
      });
      this.difficultyList[0].isClick = true;
      this.searchYearList.map((index) => {
        index.isClick = false;
      });
      this.searchYearList[0].isClick = true;
      this.examTypeOptions.map((index) => {
        index.isClick = false;
      });
      this.examTypeOptions[0].isClick = true;
      this.sortTypeList[0].isClick = true;
      this.sortTypeList[1].isClick = false;
      this.sortTypeList[2].isClick = false;
      this.checkList = [1];
      // console.log("11111111111");

      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/qusetion.scss";
.questionLocal {
  .menu-box {
    display: flex;
    margin: 20px 0;
    .el-switch {
      margin-left: 24px;
    }
    div {
      margin-right: 10px;
      cursor: pointer;
    }
    .on {
      color: $primary-color;
    }
  }
  .none-list {
    img {
      width: 400px;
      display: block;
      margin: 20px auto;
    }
    p {
      text-align: center;
      color: #999999;
    }
  }
  .loading-box {
    width: 100%;
    height: 600px;
  }

  .input-box {
    margin: 0 auto 20px;
    width: 450px;
  }
  .search-item {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 8px;
    align-items: center;
    .el-checkbox {
      margin-bottom: 0;
    }
    .input-with-select {
      width: 300px;
    }
    .title {
      margin: 0 5px 3px 5px;
      padding: 2px 10px;
      color: #333;
    }
    p {
      margin: 0 5px 3px 5px;
      padding: 2px 10px;
      color: #333;
      cursor: pointer;
      &:hover {
        background: $primary-color;
        color: #fff;
        border-radius: 3px;
      }
    }
    .on {
      background: $primary-color;
      color: #fff;
      border-radius: 3px;
    }
  }
}
</style>
