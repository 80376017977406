<template>
  <div class="knowledgePointsPaper">
    <div :key="searchKey" class="left-box">
      <div class="type-box">
        <div
          v-for="(v, i) in typeList"
          :key="i"
          :class="{ on: typeVal == v.value }"
          @click="checkTypeList(v.value)"
        >
          {{ v.name }}
        </div>
      </div>
      <!-- 左边知识点组卷的盒子 -->
      <template v-if="typeVal == 1">
        <div class="tree-type-box">
          <template v-if="!treeType">知识点目录</template>
          <el-switch
            v-else
            v-model="knowType"
            active-text="交集"
            inactive-text="并集"
          >
          </el-switch>
          <el-switch v-model="treeType" active-text="多选" inactive-text="单选">
          </el-switch>
        </div>

        <el-input
          v-model="knowVal"
          placeholder="请输入内容"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchKnow"
          ></el-button>
        </el-input>

        <div class="tree-box" :class="{ 'more-tree': !treeType }">
          <el-tree
            ref="tree"
            :key="newKeys"
            :data="knowledgeData"
            :show-checkbox="treeType"
            node-key="number"
            :default-checked-keys="loreTureArrid"
            :default-expanded-keys="twoTreeArray"
            :props="defaultProps"
            :filter-node-method="filterNode"
            @check="elTreeChange"
            @node-click="nodeClick"
          >
          </el-tree>
        </div>
      </template>
      <template v-else>
        <el-cascader
          v-model="value"
          :options="geteditionlistData"
          @change="handleChange"
        ></el-cascader>
        <div class="tree-box more-tree">
          <el-tree
            ref="tree"
            :data="getbookchapterList"
            node-key="number"
            :props="defaultProps"
            :filter-node-method="filterNode"
            @node-click="editionClick"
          >
          </el-tree>
        </div>
      </template>
    </div>
    <div class="right-box">
      <questionLocal
        v-if="!userInfo.isAllowJyeoo"
        ref="questionLocal"
      ></questionLocal>
      <qusetionJyw v-if="userInfo.isAllowJyeoo" ref="qusetionJyw"></qusetionJyw>
    </div>

    <testBasket
      :key="componentsKey"
      :plan-id="planId"
      @setQuestionList="setQuestionList"
      @setPlanid="setPlanid"
      @getNewList="getNewList"
    ></testBasket>
    <similarQuestion ref="similarQuestion"></similarQuestion>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getQuestionPoint } from "@/core/api/exam/examLore";
import {
  getbookchapterbyid,
  getbooklistbylevelandsubject,
  exampaperplanquestionDel,
  exampaperplanquestion,
} from "@/core/api/resource/knowledgePointsPaper";

import testBasket from "./components/testBasket.vue";
import qusetionJyw from "./components/qusetionJyw.vue";
import questionLocal from "./components/questionLocal.vue";
import similarQuestion from "@/components/resource/similarQuestion.vue";
import { getStore } from "@/core/util/store";
const typeList = [
  { name: "知识点组卷", value: 1 },
  { name: "同步组卷", value: 2 },
];

export default {
  name: "KnowledgePointsPaper",
  components: {
    testBasket,
    questionLocal,
    similarQuestion,
    qusetionJyw,
  },
  data() {
    return {
      searchKey: 111,
      loading: true,
      typeList: typeList,
      typeVal: 1,
      knowVal: "",
      value: [],
      treeType: false,
      knowType: true,
      noImg: true,
      hasAnswer: true,
      knowledgeData: [],
      loreTureArrid: [],
      twoTreeArray: [],
      newKeys: 0,
      defaultProps: {
        children: "child",
        label: "name",
      },
      knowledge: [],
      geteditionlistData: [],
      // 章节原始数据
      getbookchapterData: [],
      // 树形图
      getbookchapterList: [],

      questionSourceItemHigh: [],
      questionSourceItemJunior: [],
      examquestionData: [],

      componentsKey: 1,
      planId: 0,
      chapterId: "",
      current: 1,
      question: "",
      planQuestionList: [],
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    knowType() {
      if (this.userInfo.isAllowJyeoo) {
        this.$refs.qusetionJyw.getViewList();
      } else {
        this.$refs.questionLocal.getViewList();
      }
    },
    knowVal() {
      this.$refs.tree.filter(this.knowVal);
    },
    treeType() {
      this.knowledge = [];
      if (this.userInfo.isAllowJyeoo) {
        this.$refs.qusetionJyw.getViewList();
      } else {
        this.$refs.questionLocal.getViewList();
      }
    },
  },
  created() {
    // console.log("生命周期触发了");
    this.userInfo = getStore({ name: "userInfo" });
    this.getToList();
    this.geteditionlist();
  },

  methods: {
    similarity(val) {
      this.$refs.similarQuestion.init(val);
    },
    setQuestionList(val) {
      // console.log(val);
      this.planQuestionList = val;
    },

    // 移除试题篮
    exampaperplanquestionDel(val, type) {
      let data = {
        examPaperPlanId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
      };
      exampaperplanquestionDel(data).then(() => {
        // console.log(res);
        this.$message({
          message: "移除成功！",
          type: "success",
        });
        // 调用 ，更新列表
        // v.isPlan = 0;
        this.componentsKey = new Date().getTime();
      });
    },

    getNewList() {
      // v-if="userInfo.isAllowJyeoo"
      if (this.userInfo.isAllowJyeoo) {
        this.$refs.qusetionJyw.getViewList();
      } else {
        this.$refs.questionLocal.getViewList();
      }
    },
    //设置planid
    setPlanid(planId) {
      this.planId = planId;
    },
    //添加到试题篮
    exampaperplanquestion(val, type) {
      let data = {
        planId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
      };

      exampaperplanquestion(data).then(() => {
        // console.log(res);
        this.$message({
          message: "加入成功",
          type: "success",
        });
        // val.isPlan = 1;
        this.componentsKey = new Date().getTime();
      });
    },

    // 章节点击
    editionClick(val) {
      // console.log(val);
      this.chapterId = val.number;
      if (this.userInfo.isAllowJyeoo) {
        this.$refs.qusetionJyw.getViewList();
      } else {
        this.$refs.questionLocal.getViewList();
      }
    },
    handleChange(val) {
      if (val.length < 2) {
        return;
      }
      this.getbookchapterbyid(val[1]);
    },
    // /通过科目、学段查询教材列表列表
    geteditionlist() {
      getbooklistbylevelandsubject({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        this.geteditionlistData = [];
        let array = [];
        res.data.data.forEach((index) => {
          if (array.length == 0 || array.indexOf(index.editionId) == -1) {
            array.push(index.editionId);
            this.geteditionlistData.push({
              label: index.editionName,
              value: index.editionId,
              children: [],
            });
          }
          if (array.indexOf[index.editionId] != -1) {
            this.geteditionlistData.forEach((item) => {
              if (item.value == index.editionId) {
                item.children.push({
                  label:
                    index.gradeName +
                    index.editionName +
                    index.termName +
                    index.name,
                  value: index.id,
                });
              }
            });
          }
        });
        if (this.geteditionlistData.length > 0) {
          this.value.push(this.geteditionlistData[0].value);
          this.value.push(this.geteditionlistData[0].children[0].value);
          this.getbookchapterbyid(this.value[1]);
        }

        // console.log(this.geteditionlistData);
      });
    },
    // 通过id获取章节
    getbookchapterbyid(editionId) {
      getbookchapterbyid(editionId).then((res) => {
        // console.log(res);
        this.getbookchapterList = [];

        this.getbookchapterData = res.data.data;
        let arrList = this.getbookchapterData;
        // 递归生成一个新树形节点
        // console.log(arrList.filter(i => i.rank == 1 && i.name != "听力"));
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.getbookchapterList.push(obj);
          });
      });
    },
    checkTypeList(i) {
      if (i == this.typeVal) {
        return;
      }
      this.typeVal = i;
    },
    filterNode(value, data) {
      // console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //点击了当前项
    nodeClick(val) {
      //如果是多选就不管了
      if (this.treeType) {
        return;
      }
      this.knowledge = [];
      this.knowledge.push(val.number);
      if (this.userInfo.isAllowJyeoo) {
        this.$refs.qusetionJyw.getViewList();
      } else {
        this.$refs.questionLocal.getViewList();
      }
      // console.log(val);
    },
    // getKnowledgeList() {
    //   console.log(this.knowledge);
    // },
    // 知识点搜索
    searchKnow() {
      this.$refs.tree.filter(this.knowVal);
    },
    elTreeChange() {
      // console.log("11111111");
      this.knowledge = [];
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();

      if (this.knowVal == "") {
        this.knowledge = this.loreTureArrid;
        if (this.userInfo.isAllowJyeoo) {
          this.$refs.qusetionJyw.getViewList();
        } else {
          this.$refs.questionLocal.getViewList();
        }
        return;
      }
      //如果存在搜索值，就过滤下
      this.loreArr.forEach((item) => {
        if (this.loreTureArrid.indexOf(item.number) != -1) {
          if (item.name.indexOf(this.knowVal) != -1) {
            this.knowledge.push(item.number);
          }
        }
      });
      if (this.userInfo.isAllowJyeoo) {
        this.$refs.qusetionJyw.getViewList();
      } else {
        this.$refs.questionLocal.getViewList();
      }
      // this.knowVal
    },
    //组装知识点的树形结构
    getToList() {
      getQuestionPoint({
        level: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        this.loreArr = res.data.data.resQuestionPoints;
        let arrList = this.loreArr;
        arrList
          .filter((i) => i.rank == 1 && i.name != "听力")
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.knowledgeData.push(obj);
          });
        // console.log(this.loreArr);
      });
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      // console.log(result);
      return result;
    },
  },
};
</script>
<style lang="scss">
.knowledgePointsPaper {
  img {
    max-width: 100%;
  }
  .input-with-select {
    border-radius: 4px;
    overflow: hidden;
    .el-button {
      background-color: #2474ed;
      border-radius: 0px 4px 4px 0;
      border: 1px solid #2474ed;
    }
    i {
      color: #ffffff;
    }
  }
  //多选就不高亮
  .more-tree {
    .is-current.is-focusable {
      > .el-tree-node__content {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
    .el-tree-node:focus {
      > .el-tree-node__content {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
  }
  .tree-box {
    label {
      margin-bottom: 0;
    }
    //修改树形控件基础样式
    .el-tree-node > .el-tree-node__children {
      overflow: unset;
    }
  }
}
</style>

<style scoped lang="scss">
.knowledgePointsPaper {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .left-box,
  .right-box {
    padding: 20px;
    border: 1px solid #dddddd;
  }
  .left-box {
    width: 300px;
    padding: 12px;
    .input-with-select {
      margin: 12px 0;
    }
    .el-cascader {
      width: 100%;
      margin: 12px 0;
    }
    .tree-box {
      height: calc(100vh - 300px);
      width: 100%;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #dcdfe6;
      }
    }
    .tree-type-box {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      color: $primary-color;
    }
    .type-box {
      display: flex;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #dddddd;
      div {
        width: 50%;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;

        &:first-child {
          border-right: 1px solid #dddddd;
        }
      }
      .on {
        background: $primary-color;
        color: #ffffff;
      }
    }
  }
  .right-box {
    width: 900px;
  }
}
</style>
