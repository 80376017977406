<template>
  <div class="qusetionJyw">
    <div class="search-box">
      <!-- <div class="input-box">
        <el-input
          v-model="question"
          placeholder="请输入内容"
          class="input-with-select"
          :debounce="500"
          @keyup.enter.native="searchList"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchList"
          ></el-button>
        </el-input>
      </div> -->
      <div class="search-item">
        <div class="title">题型：</div>
        <div class="child-box">
          <p
            v-for="(v, i) in questionList"
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, questionList)"
          >
            {{ v.label }}
          </p>
        </div>
      </div>
      <div class="search-item filter-item">
        <div class="filter-left">
          <div class="title">难度：</div>
          <template v-for="(v, i) in difficultyList">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, difficultyList)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <!-- <div class="filter-right">
          <el-button type="text" style="margin-right: 4px">自定义</el-button>
          <el-input
            v-model="difficultyOne"
            size="mini"
            placeholder=""
            class="input-class"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @blur="difficultyOne = $event.target.value"
          >
          </el-input>
          <span>&nbsp; - &nbsp;</span>
          <el-input
            v-model="difficultyTwo"
            class="input-class"
            size="mini"
            placeholder=""
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @blur="difficultyOne = $event.target.value"
          >
          </el-input>
          <el-button type="text" style="margin-left: 4px">确定</el-button>
        </div> -->
      </div>
      <div class="search-item">
        <div class="title">题类：</div>
        <template v-for="(v, i) in strQuuestionItem">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, strQuuestionItem)"
          >
            {{ v.label }}
          </p>
        </template>
      </div>
      <div class="search-item filter-item">
        <div class="filter-left">
          <div class="title">年份：</div>
          <template v-for="(v, i) in searchYearList">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, searchYearList)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <!-- <div class="filter-right">
          <span class="tips-err">-12100</span>
          <span>道</span>
        </div> -->
      </div>

      <div class="search-item">
        <div class="title">来源：</div>
        <div class="child-box">
          <p
            v-for="(v, i) in questionSourceItem"
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, questionSourceItem, checkType)"
          >
            {{ v.label }}
          </p>
        </div>
        <div class="check-type">
          <el-checkbox
            v-model="checkType"
            @change="setListDefault('questionSourceItem')"
          >
            来源多选
          </el-checkbox>
        </div>
      </div>
      <!-- <div class="search-item">
        <div class="title">能力：</div>
        需要接口
        <template v-for="(v, i) in examTypeOptions">
          <p
            :key="i"
            :class="{ on: v.isClick }"
            @click="setItem(v, examTypeOptions)"
          >
            {{ v.label }}
          </p>
        </template>
      </div> -->
      <div class="search-item filter-item">
        <div class="filter-left">
          <div class="title">地区:</div>
          <!-- <el-select
            v-model="searchData.grade"
            placeholder="请选择年级"
            filterable
          >
            <el-option
              v-for="item in gradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->

          <el-select
            v-model="searchData.province"
            placeholder="请选择省份"
            filterable
            @change="checkPro"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="item in provinceGroup"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="cityGroup.length > 0 && searchData.province"
            v-model="searchData.city"
            placeholder="请选择市区"
            filterable
            @change="checkCity"
          >
            <el-option label="全部" value="-1"> </el-option>
            <el-option
              v-for="item in cityGroup"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="filter-right">
          <el-checkbox v-model="checkType"> 课本原题 </el-checkbox>
          <el-checkbox v-model="checkType"> 过滤已使用 </el-checkbox>
        </div> -->
      </div>
      <div class="search-item filter-item">
        <div class="filter-left">
          <div class="title">排序:</div>
          <div
            v-for="(item, index) in sortTypeList"
            :key="index"
            class="sort-box"
            :class="{ 'sort-box-on': searchData.sortType == item.value }"
            @click="checkSort(item)"
          >
            {{ item.name }}
            <div class="sort-icon">
              <i
                class="el-icon-top"
                :class="{ 'sort-on': searchData.sortOrder == 1 }"
              >
              </i>
              <i
                class="el-icon-bottom"
                :class="{ 'sort-on': searchData.sortOrder == 0 }"
              >
              </i>
            </div>
          </div>
        </div>
        <!-- <div>
          <el-switch
            v-model="answerType"
            active-text="显示答案"
            inactive-text="隐藏答案"
          >
          </el-switch>
        </div> -->
      </div>
    </div>

    <div v-if="loading" v-loading="loading" class="loading-box"></div>
    <div v-if="!loading" class="question-box">
      <div v-for="(v, i) in examquestionData" :key="i" class="question-list">
        <div class="question-id">试题ID：{{ v.id }}</div>
        <div style="padding: 0 12px 12px" v-html="v.question"></div>
        <div class="btm-box">
          <div class="tips">
            <span>更新:{{ v.createTime | setTimeStyle }}</span>
            <!-- <span>{{ v.typeName }}</span> -->
            <span>难度:{{ v.difficulty }} </span>
          </div>

          <div class="btn-box">
            <span :class="{ succ: v.isRelation }" @click="relation(v)">
              <template v-if="v.isRelation">已</template>收藏</span
            >
            <span @click="show(v)">解析</span>
            <span @click="downFile(v)">下载</span>
            <span @click="$parent.similarity(v)">同类题</span>
            <el-button
              v-if="$parent.planQuestionList.indexOf(v.id) == -1"
              type="primary"
              class="btn-all"
              @click="$parent.exampaperplanquestion(v)"
            >
              加入试题篮
              <i class="el-icon-plus el-icon--right"></i>
            </el-button>
            <el-button
              v-else
              type="info"
              @click="$parent.exampaperplanquestionDel(v)"
            >
              移除试题篮
              <i class="el-icon-minus el-icon--right"></i>
            </el-button>
          </div>
        </div>
        <!-- 考情 -->
        <div v-if="situationArr.indexOf(v.id) != -1" class="situation-box">
          <template
            v-if="v.situation && v.situation.anyExamUseQuestion.length == 0"
          >
            数据尚未同步
          </template>
          <template v-else>
            <p>
              <span class="situation-box-name">考情：</span>本题共
              <span>{{ v.situation.countReply }}</span> 人使用,共{{
                v.situation.examNum
              }}场考试
            </p>
            <div
              v-for="(m, n) in v.situation.anyExamUseQuestion"
              :key="n"
              class="situation-list"
            >
              <div class="situation-list-left">
                <p>{{ m.examName }}</p>
                <div>
                  <span class="situation-list-name">{{ m.examDate }}</span>
                  <span> | </span>
                  <span class="situation-list-name"
                    >共{{ m.countReply }}人参考</span
                  >
                  <span> | </span>
                  <span class="situation-list-name">{{ m.schoolLevel }}</span>
                </div>
              </div>
              <div class="situation-list-right">
                {{ m.scoreRate | setNumber }}
              </div>
            </div>
          </template>
        </div>
        <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
          <div v-if="v.newQuestion" class="new-question-box">
            <p v-if="v.newQuestion" class="question-tips">
              当前试题没有找到答案解析，已推荐同类题
            </p>
            <div class="analysis-item">
              【同类题】
              <div v-html="v.newQuestion"></div>
            </div>
            <el-button
              v-if="$parent.planQuestionList.indexOf(v.newId) == -1"
              type="primary"
              class="btn-all"
              @click="$parent.exampaperplanquestion(v, 1)"
            >
              加入试题篮
              <i class="el-icon-plus el-icon--right"></i>
            </el-button>
            <el-button
              v-else
              type="info"
              @click="$parent.exampaperplanquestionDel(v, 1)"
            >
              移除试题篮
              <i class="el-icon-minus el-icon--right"></i>
            </el-button>
          </div>

          <div
            v-if="v.knowledgeNames && v.knowledgeNames.split('#').length > 0"
            class="know-box analysis-item"
          >
            【知识点】
            <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
              {{ m }}
            </div>
          </div>
          <div v-if="v.answer && v.answer.length > 0" class="analysis-item">
            【答案】
            <div v-html="v.answer"></div>
          </div>
          <div v-if="v.analysis && v.analysis.length > 0" class="analysis-item">
            【解答】
            <div v-html="v.analysis"></div>
          </div>
          <div v-if="v.analyse && v.analyse.length > 0" class="analysis-item">
            【分析】
            <div v-html="v.analyse"></div>
          </div>
          <div v-if="v.ability && v.ability.length > 0" class="analysis-item">
            【点评】
            <div v-html="v.ability"></div>
          </div>
          <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
        </div>
      </div>
    </div>
    <div v-if="examquestionData.length == 0 && !loading" class="none-list">
      <img src="@/static/resources/none.svg" alt="" />
      <p>暂未找到数据</p>
    </div>
    <!-- <el-pagination
      :hide-on-single-page="true"
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="current"
      @current-change="pageList"
    >
    </el-pagination> -->
    <div class="el-pagination-box">
      <el-button
        type="primary"
        size="mini"
        plain
        :disabled="current == 1"
        @click="getBtnNew(-1)"
      >
        上一页
      </el-button>
      当前第
      <div class="current-text">
        {{ current }}
      </div>
      页
      <el-button
        type="primary"
        :disabled="examquestionData.length == 0 || examquestionData.length < 10"
        size="mini"
        plain
        @click="getBtnNew(1)"
      >
        下一页</el-button
      >
    </div>
  </div>
</template>
<script>
import { getquestiontype } from "@/core/api/resource/breakdownPaper";
import {
  questionType,
  examTypeOptions,
  difficultyList,
  difficultyListCopy,
} from "@/core/util/constdata";
import {
  examquestionSearch,
  answer,
  situation,
  examquestionrelationMy,
  examquestionrelationDel,
  listTree,
  resourceList,
  downloadJyeooWord,
} from "@/core/api/resource/knowledgePointsPaper";
import { fileDownloadByUrl } from "@/core/util/util";
const sortTypeList = [
  { name: "综合排序", value: 0 },
  { name: "最热", value: 1 },
  // { name: "真题次数", value: 2 },
  { name: "难度", value: 3 },
];
// const
export default {
  name: "QuestionLocal",
  data() {
    return {
      gradeOptions: [],
      searchData: {
        sortOrder: 0,
        sortType: 0,
        city: "",
        province: "",
      },
      answerType: false,
      loading: false,
      current: 1,
      difficultyTwo: 0.9,
      difficultyOne: 0.1,
      question: "",
      examquestionData: [],
      // 搜索
      searchYearList: [],
      difficultyList: difficultyList,

      questionType: questionType,
      questionList: [],
      sortTypeList: sortTypeList,
      difficultyListCopy: difficultyListCopy,
      examTypeOptions: examTypeOptions,
      checkList: [1],
      total: 0,
      planQuestionList: [],
      checkType: false,
      questionSourceItemHigh: [],
      strQuuestionItem: [],
      questionSourceItemJunior: [],
      questionSourceItem: [],
      // 判断是否显示考情
      situationArr: [],
      // 判断列表是否显示解析
      showListArr: [],
      provinceGroup: [],
      cityGroup: [],
    };
  },
  created() {
    this.setSearchList();
    this.initItem();
    this.getList();
    this.getlistTree();
  },
  mounted() {
    window.MathJax.typesetPromise();
  },
  updated() {
    window.MathJax.startup.defaultReady();
    // window.MathJye.LayOut(document.body);
  },
  methods: {
    downFile(v) {
      let data = {
        jyeooId: v.xuekeId,
      };
      if (v.downLoading) return;
      v.downLoading = true;
      downloadJyeooWord(data)
        .then((res) => {
          let { url, name } = res.data.data;
          fileDownloadByUrl(url, name);
          // const { href } = this.$router.resolve({
          //   path: "/downFile",
          //   query: {
          //     url: url,
          //     name: name,
          //     // type: 1,
          //   },
          // });
          // window.open(href, "_blank");
          v.downLoading = false;
        })
        .catch(() => {
          v.downLoading = false;
        });
    },
    getBtnNew(val) {
      this.current = this.current + val;
      if (this.current < 1) this.current = 1;
      this.getList();
    },
    getResourceList() {
      let data = {
        levelId: this.$parent.getLevelId,
        subjectId: this.$parent.getSubjectId,
      };
      resourceList(data).then((res) => {
        //         this.questionSourceItem
        let array = res.data.data.map((item) => {
          item.value = item.id;
          item.label = item.name;
          item.isClick = false;
          return item;
        });
        this.questionSourceItem = [
          {
            value: 0,
            label: "全部",
            isClick: true,
          },
        ].concat(array);
      });
    },
    checkSort(item) {
      if (item.value == this.searchData.sortType) {
        this.searchData.sortOrder = this.searchData.sortOrder == 0 ? 1 : 0;
      } else {
        this.searchData.sortType = item.value;
        this.searchData.sortOrder = 0;
      }
      this.filterList();
    },
    checkPro(val) {
      this.provinceGroup.map((item) => {
        if (item.id == val) {
          this.cityGroup = item.children;
        }
      });
      this.searchData.city = "";
      if (this.cityGroup.length == 0 || !this.searchData.province) {
        this.filterList();
      }
    },
    checkCity() {
      this.filterList();
    },
    getlistTree() {
      let data = { maxType: 1 };
      listTree(data)
        .then((res) => {
          // console.log(res);
          this.provinceGroup = res.data.data;
        })
        .catch(() => {});
    },
    // 收藏
    relation(v) {
      let data = {
        examQuestionId: v.id,
        type: 1,
      };
      if (v.isRelation == 1) {
        this.$confirm("确认取消收藏当前试题吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            examquestionrelationDel(data).then(() => {
              v.isRelation = 0;
              this.$message({
                message: "已取消收藏",
                type: "success",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消“取消收藏”操作",
            });
          });
      } else {
        examquestionrelationMy(data).then(() => {
          // console.log();
          this.$message({
            message: "已收藏",
            type: "success",
          });
          v.isRelation = 1;
        });
      }
    },
    //获取考情
    situation(v) {
      if (v.situation) {
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        return;
      }
      // console.log();
      situation(v.id).then((res) => {
        v.situation = res.data.data;
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
      });
    },
    // 切换是否显示页面列表
    show(v) {
      if (this.showListArr.indexOf(v.id) != -1) {
        this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
        return;
      }
      answer(v.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            v.newId = res.data.data.id;
            v.newXuekeId = res.data.data.xuekeId;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    initItem() {
      // let str =
      //   "全部 中考真题 自主招生 中考模拟 中考复习 期末试题 期中试题 月考试题 开学试题 单元测验 同步练习 竞赛试题 假期作业 初高衔接";
      // let arr = str.split(" ");
      // this.questionSourceItemJunior = arr.map((item, index) => {
      //   return {
      //     label: item,
      //     value: index,
      //     isClick: index == 0 ? true : false,
      //   };
      // });

      // let strNew =
      //   "全部 高考真题 学业考试 自主招生 高考模拟 高考复习 期末试题 期中试题 月考试题 开学试题 单元测验 同步练习 竞赛试题 假期作业 模块试题";
      // let arrNew = strNew.split(" ");
      // this.questionSourceItemHigh = arrNew.map((item, index) => {
      //   return {
      //     label: item,
      //     value: index,
      //     isClick: index == 0 ? true : false,
      //   };
      // });
      this.getResourceList();
      let strQuuestion = "全部 常考题 易错题 好题 压轴题";
      let strQuuestionArr = strQuuestion.split(" ");
      this.strQuuestionItem = strQuuestionArr.map((item, index) => {
        return {
          label: item,
          value: index,
          isClick: index == 0 ? true : false,
        };
      });
    },
    onValue(list) {
      let value = [];
      list.forEach((index) => {
        if (index.isClick) {
          value.push(index.value);
        }
      });
      return value.join(",");
    },
    // onValues(list) {
    //   let value = [];
    //   list.forEach((index) => {
    //     if (index.isClick) {
    //       value.push(index.value);
    //     }
    //   });
    //   return value.join(",");
    // },
    filterList() {
      this.current = 1;
      this.getList();
    },
    pageList(i) {
      this.current = i;
      this.getList();
    },
    getViewList(i) {
      this.current = i || this.current;
      this.getList();
    },
    getList() {
      let data = {
        size: 10,
        current: this.current,
        level: this.$parent.getLevelId,
        subjectId: this.$parent.getSubjectId,
        // planId: this.planId,
        question: this.question,
        isAllowJyeoo: 1,
      };
      this.situationArr = [];
      this.showListArr = [];
      data.isSingle = this.treeType ? 0 : 1;
      // if (this.treeType) {
      data.isUnion = this.knowType ? 0 : 1;
      // }
      if (!this.treeType) {
        data.isUnion = 1;
      }
      if (this.checkList.indexOf(1) != -1) {
        data.noImg = 1;
      } else {
        data.noImg = 0;
      }
      if (this.checkList.indexOf(2) != -1) {
        data.hasAnswer = 1;
      } else {
        data.hasAnswer = 0;
      }
      // data.noImg = this.noImg ? 0 : 1;
      // data.hasAnswer = this.hasAnswer ? 0 : 1;
      // treeType
      // knowType
      //题型
      data.typeName = this.onValue(this.questionList);
      // 难度
      data.difficulty = this.onValue(this.difficultyList);
      // 年份
      data.year = this.onValue(this.searchYearList);
      // 题类
      data.quesCate = this.onValue(this.strQuuestionItem);
      // 考试类型
      data.examType = this.onValue(this.examTypeOptions);
      // 来源
      // if (this.$parent.getLevelId == 2) {
      data.questionSource = this.onValue(this.questionSourceItem);
      // }
      // if (this.$parent.getLevelId == 3) {
      //   data.questionSource = this.onValue(this.questionSourceItemHigh);
      // }
      if (this.searchData.city != "-1" && this.searchData.city) {
        data.questionArea = this.searchData.city;
      } else {
        data.questionArea = this.searchData.province;
      }

      // 排序  最新  最热
      // data.sortType = this.onValue(this.sortTypeList);
      Object.assign(data, this.searchData);
      // typeVal 为1就是知识点 ，为2就是章节
      if (this.$parent.typeVal == 1) {
        data.knowledgeIds = this.$parent.knowledge;
        data.chapterIds = "";
        if (data.knowledgeIds.length > 15) {
          this.$message({
            message: "所选知识点不能超过15个",
            type: "warning",
          });
          return;
        }
      } else {
        data.knowledgeIds = [];
        data.chapterIds = this.$parent.chapterId;
        let arr = this.$parent.getbookchapterData.filter(
          (item) => item.number == data.chapterIds
        );
        if (arr.length > 0) {
          data.jyeooId = arr[0].jyeooId;
        } else {
          data.jyeooId = "";
        }

        // this.getbookchapterData.forEach()
      }
      this.loading = true;
      examquestionSearch(data)
        .then((res) => {
          this.loading = false;

          this.examquestionData = res.data.data.records.map((item) => {
            if (item.source) {
              item.question = item.question.replace(
                ">",
                `><span class='jyw-question-tips'>${item.source}</span>`
              );
            }
            item.downLoading = false;
            return item;
          });
          if (res.data.data.total < 50) {
            this.total = 50;
          } else {
            this.total = res.data.data.total;
          }

          this.showListArr = [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 恢复默认值
    setListDefault() {
      // val
      if (!this.checkType) {
        this.questionSourceItem = this.questionSourceItem.map((item, index) => {
          item.isClick = false;
          if (index == 0) item.isClick = true;
          return item;
        });
        // this.questionSourceItemHigh = this.questionSourceItemHigh.map(
        //   (item, index) => {
        //     item.isClick = false;
        //     if (index == 0) item.isClick = true;
        //     return item;
        //   }
        // );
        this.getList();
      }
    },
    // 切换
    setItem(val, list, type) {
      list.map((index) => {
        if (!type) {
          index.isClick = false;
        }
        if (index.value == val.value) {
          list[0].isClick = false;
          index.isClick = !index.isClick;
        }
      });
      // 多选的时候判断 如果说都不选  就默认全部  如果点了全部  就取消其他的  来源菁优网
      if (type) {
        if (val.value == list[0].value) {
          list.map((index) => {
            index.isClick = false;
          });
        }
        let arr = list.filter((item) => item.isClick);
        if (arr.length == 0) {
          list[0].isClick = true;
        }
      }
      this.current = 1;
      //判断下如果存在搜索条件 就将排序方式改为综合
      if (this.question != "" && this.sortTypeList[0].name == list[0].name) {
        this.sortTypeList[0].isClick = true;
        this.sortTypeList[1].isClick = false;
        this.sortTypeList[2].isClick = false;
        return;
      }
      this.getList();
    },

    setSearchList() {
      // 搜索用的年份
      let year = new Date().getFullYear();
      this.searchYearList.push({
        label: "全部",
        value: -1,
        isClick: true,
      });
      for (let i = 0; i < 4; i++) {
        this.searchYearList.push({
          label: year - i,
          value: year - i,
          isClick: false,
        });
      }
      this.searchYearList.push({
        label: "更早",
        value: -2,
        isClick: false,
      });
      let arr = [
        {
          value: "0-1",
          label: "全部",
          isClick: true,
        },
      ];
      //难度列表循环
      this.difficultyList.map((item) => {
        item.isClick = false;
      });
      this.difficultyList = arr.concat(this.difficultyList);
      this.difficultyList = JSON.parse(JSON.stringify(this.difficultyList));
      // 通过科目、学段查询题型
      this.questionList = [];
      this.questionList.push({ label: "全部", value: -1, isClick: true });
      getquestiontype({
        levelId: this.$parent.getLevelId,
        subjectId: this.$parent.getSubjectId,
      }).then((res) => {
        res.data.data.forEach((index) => {
          this.questionList.push({
            value: index.name,
            label: index.name,
            isClick: false,
          });
        });
      });

      this.examTypeOptions.map((item) => {
        item.isClick = false;
      });
      this.examTypeOptions = [
        { label: "全部", value: -1, isClick: true },
      ].concat(this.examTypeOptions);
      this.examTypeOptions = JSON.parse(JSON.stringify(this.examTypeOptions));
    },
    searchList() {
      this.current = 1;
      this.searchKey = new Date().getTime();
      this.knowledge = [];
      this.chapterId = "";
      // difficultyList searchYearList examTypeOptions
      this.difficultyList.map((index) => {
        index.isClick = false;
      });
      this.difficultyList[0].isClick = true;
      this.searchYearList.map((index) => {
        index.isClick = false;
      });
      this.searchYearList[0].isClick = true;
      this.examTypeOptions.map((index) => {
        index.isClick = false;
      });
      this.examTypeOptions[0].isClick = true;
      this.sortTypeList[0].isClick = true;
      this.sortTypeList[1].isClick = false;
      this.sortTypeList[2].isClick = false;
      this.checkList = [1];
      // console.log("11111111111");

      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/qusetion.scss";
.qusetionJyw {
  .el-pagination-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 12px;
    .el-button {
      &:first-child {
        margin-right: 18px;
      }
      &:last-child {
        margin-left: 18px;
      }
    }
    .current-text {
      border: 1px solid #dddddd;
      padding: 4px 18px;
      border-radius: 4px;
      margin: 0 6px;
      color: #2474ed;
      background-color: #f5f7fa;
    }
  }
  .sort-box {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
    cursor: pointer;
    padding: 2px 6px;
    .sort-icon {
      margin-left: 2px;
      i {
        display: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .sort-box-on {
    color: #ffffff;
    background-color: #2474ed;
    border-radius: 4px;
    .sort-icon {
      .sort-on {
        display: block;
      }
    }
  }
  .tips-err {
    color: red;
  }
  .menu-box {
    display: flex;
    margin-top: 20px;
    .el-switch {
      margin-left: 24px;
    }
    div {
      margin-right: 10px;
      cursor: pointer;
    }
    .on {
      color: $primary-color;
    }
  }
  .none-list {
    img {
      width: 400px;
      display: block;
      margin: 20px auto;
    }
    p {
      text-align: center;
      color: #999999;
    }
  }
  .loading-box {
    width: 100%;
    height: 600px;
  }

  .input-box {
    margin: 0 auto 20px;
    width: 450px;
  }
  .filter-item {
    justify-content: space-between;
    .filter-left {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      .el-select {
        width: 120px;
        margin-right: 8px;
      }
    }
    .filter-right {
      display: flex;
      flex-flow: wrap;
      align-items: center;

      .input-class {
        width: 50px;
      }
    }
  }
  .search-item {
    display: flex;

    margin-bottom: 8px;
    align-items: flex-start;
    position: relative;
    .child-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .check-type {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .el-checkbox {
      margin-bottom: 0;
    }
    .input-with-select {
      width: 300px;
    }
    .title {
      margin: 0 5px 3px 5px;
      padding: 2px 10px;
      color: #333;
      flex-shrink: 0;
    }
    p {
      margin: 0 5px 3px 5px;
      padding: 2px 10px;
      color: #333;
      cursor: pointer;
      &:hover {
        background: $primary-color;
        color: #fff;
        border-radius: 3px;
      }
    }
    .on {
      background: $primary-color;
      color: #fff;
      border-radius: 3px;
    }
  }
}
</style>
